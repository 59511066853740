import { inject } from '@angular/core';
import { CustomCookieService } from './services/custom-cookie.service';
import { REQUEST } from './ssr-headers/headers.config';

import { HttpInterceptorFn } from '@angular/common/http';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
	const request = inject(REQUEST, { optional: true });
	const customCookieSrv = inject(CustomCookieService);

	const url = req.url;

	if (request?.headers?.cookie) {
		req = req.clone({
			setHeaders: {
				Cookie: request.headers.cookie,
			},
		});
	}

	// append authorisation header if going to new endpoint.
	if (url.includes('/api') && !url.includes('api.ipify.org')) {
		const token = customCookieSrv.get('token');

		if (token) {
			req = req.clone({
				setHeaders: {
					Authorization: `Bearer ${token}`,
				},
			});
		}
	}

	return next(req);
};
